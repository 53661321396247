import React, { useState } from 'react';
import Confetti from 'react-confetti';

function App() {
  const [email, setEmail] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    // Dummy function to simulate email validation and submission
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (emailRegex.test(email)) {
      setIsSubmitted(true); // Change form content on successful email validation
    } else {
      alert('Please enter a valid email address.');
    }
  };

  return (
    <div className="flex flex-col min-h-screen bg-cover bg-center" style={{ backgroundImage: "url('/images/NatureBg.jpg')" }}>
      {/* Confetti */}
      <Confetti
        width={window.innerWidth}
        height={window.innerHeight}
      />

      {/* Header */}
      <header className="w-full py-3 bg-black bg-opacity-50 text-center fixed top-0 z-50">
        <h1 className="text-3xl md:text-5xl lg:text-6xl font-bold text-white">Navodayan Travel</h1>
      </header>

      {/* Main Content */}
      <main className="flex-grow flex flex-col items-center justify-center mt-16 relative">
        <div className="text-center w-full max-w-lg mx-4 relative z-10">
          <p className="text-5xl md:text-7xl font-extrabold mb-4 text-white drop-shadow-lg">Coming Soon</p>
          <p className="text-base md:text-lg text-white bg-black bg-opacity-50 px-4 py-2 rounded-md">Explore the world with us!</p>
        </div>
      </main>

      {/* Subscribe Section */}
      <section className="bg-black bg-opacity-90 w-full text-center py-4 px-4">
        {!isSubmitted ? (
          <>
            <h2 className="text-2xl font-bold text-white mb-2">Subscribe</h2>
            <p className="text-white text-sm mb-4">Sign up to be the first to get updates.</p>
            <form className="max-w-md mx-auto" onSubmit={handleSubmit}>
              <input
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                className="w-full p-2 mb-3 rounded-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              <button
                type="submit"
                className="w-full bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 rounded-md transition"
              >
                Sign Up
              </button>
            </form>
          </>
        ) : (
          <p className="text-2xl font-bold text-white">Thanks so much for signing up!</p>
        )}
      </section>

      {/* Footer */}
      <footer className="w-full py-2 bg-black bg-opacity-50 text-center">
        <p className="text-white text-xs">&copy; 2024 Navodayan Travel. All rights reserved.</p>
      </footer>
    </div>
  );
}

export default App;
